export function arrayIncludes(base_array, searchArray) {
  let is_found = false;
  searchArray.forEach(element => {
    if (base_array.includes(element)) {
      is_found = true;
    }
  });
  return is_found;
}
export function formatPrice(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
// export function getBase64(file) {
//   // return new Promise(function(resolve, reject) {
//   //   let reader = new FileReader();
//   //   let imgResult = "";
//   //   reader.readAsDataURL(file);
//   //   reader.onload = function() {
//   //     imgResult = reader.result;
//   //   };
//   //   reader.onerror = function(error) {
//   //     reject(error);
//   //   };
//   //   reader.onloadend = function() {
//   //     resolve(imgResult);
//   //   };
//   // });
// }
export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export function checkProperty(object, field) {
  return Object.prototype.hasOwnProperty.call(object, field);
}
export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let imgResult = "";
    reader.readAsDataURL(file);
    reader.onload = () => {
      imgResult = reader.result;
    };
    reader.onerror = error => {
      reject(error);
    };
    reader.onloadend = () => {
      resolve(imgResult.split(",")[1]);
    };
  });
};
